.footer-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    justify-content: center;
    align-items: center;
    background-color: var(--footer-color);
    height: 100%;
    color: var(--text-white);
}

.social-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
    color: var(--text-white);
    z-index: 3;
    text-shadow: 3px 3px 2px rgba(76, 76, 76, 1);
}

.social-list li {
    margin-bottom: 2em; 
}

.social-list-container {
    grid-column: 2; 
    padding-top: 1vh
}
.form-container {
    grid-column:3 ;
    grid-row: 1 
}

.links {
    color: var(--text-white);
}

.social-icon { 
    filter: drop-shadow(3px 3px 2px rgba(76, 76, 76, 1));
    padding-right: 4px;
    font-size: 32px;
    color: #fff;
}

@media screen and (max-width: 768px) {
  .contact-info-container,
  .research-list-container {
    display: none;
  }

  .footer-container {
    grid-template-columns: repeat(3, 1fr);
    height: auto;
    padding: 1rem;
    gap: 1rem;
  }
  .social-list-container {
    grid-column: 1;
    grid-row: 1;
    padding-left: 2rem
  }


  .form-container {
    grid-column: 2 ;
  }

  .links {
    display: none
  }
}

@media screen and (max-width: 480px) {
  .social-list-container {
    grid-column: 1;
    grid-row: 1;
  }
  .social-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items:center;
    margin-top: 1em;
    padding-top: 2vh;
  }

  .social-icon {
    font-size: 30px;
  }
  .form-container {
    grid-column: 1 / span 2 ;
    grid-row: 2;
  }

  .footer-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    padding: 1rem;
    gap: 1rem;
    height: auto;
  }


}

