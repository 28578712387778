/* .about-wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 2em 0;
    flex-wrap: wrap;
    z-index: 0;
    
} */

.about-container {
    display: flex;
    width: 40vw;
    /* right: 0; */
    top: 50%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    
    /* margin-left: 3em; */
}

/* @keyframes fadeAnimation {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    98% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
} */


.about-image-container {
    width: 90%;
    max-width: 500px;
    height: 90%;
    max-height:500px;
    background-size: cover;
    background-position: center;
    opacity: 1;
    border-radius: 2em;
    z-index: 3;
    background: rgba(255, 255, 255)
    
}

.about-image {
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height:500px;
    background-size: cover;
    background-position: center;
    opacity: 1;
    animation: fadeAnimation 8s infinite;
    border-radius: 2em;
    z-index: 3;
  }

.fade-transition {
    animation: none;
}


@media screen and (max-width: 768px) {
    .about-container {
        width: 50vw;
    }
}
