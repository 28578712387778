.public-speaking-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;

}
.public-speaking-container div {

}

.public-speaking-container,
 .public-speaking-title {
    display: flex;
    flex-direction: column;
}

.video-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    gap: 1rem;
    
    
}

.video-container div {
    margin: 0.5rem;
    padding: 0.5rem;
}


.video-title {
    padding-left: 1.3em;
    margin-bottom: 0;
    text-decoration: underline;
    font-size:1.2rem;
}

.video-grid-2 {
    grid-column: 2;
}

.row-1 {
    grid-row: 1;
}

.video-grid-3 {
    grid-column: 3;
}

.row-2 {
    grid-row: 2;
}

.row-3 {
    grid-row: 3;
}

.row-4 {
    grid-row: 4;
}

.videos {
    /* border: 1px solid;
    border-color:var(--title-color); */
    width: 35vw; 
    height: auto;
}
/* .player-wrapper {
    width: auto; 
    height: auto; 
  } */

  .vod-player {
    width: 95% !important;
}

@media screen and (max-width: 480px) {
    .video-title {
        font-size:3.2vw;
        padding-bottom:0;
    }
    
    .video-container {
        display: flex;
        flex-direction: column;
    }
    .videos {
        border: none;
        border-color: none;
        width: 90vw !important; 
        height: 40vh !important;
    }
    .vod-player {
        width:95% !important; 
        height: 80% !important;
        padding: 0 !important;
        margin: 0 !important
    }
}