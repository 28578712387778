.navBar-container {
    position: fixed;
    inset-block: 0;
    left: -100%;
    background-color: var(--container-color);
    width: 100%;
    padding: 90px 40px; 
    transition: all 0.5s cubic-bezier(0.77, 0, 0.05, 1);
}

.navBar-link {
    display: flex;
    align-items: center;
    column-gap: 24px;
    border-bottom: 1px solid var(--border-color);
    padding-block: 24px; 
    position: relative;
}

.navBar-icon,
.navBar-name {
    color: var(--first-color);
    font-size: 2rem; 
}

.navBar-name {
    font-weight: var(--weight-500);
    position: absolute;
    top: 0;
    right: 0;
    color: var(--first-color);
    height: 100%;
    line-height: 50px;
    padding-inline: 10px;
    border-radius: 0 30px 30px 0;
    font-size: var(--small-font-size);
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
    z-index:10;
    white-space: nowrap;
}

.active-nav .navBar-icon {
    color: var(--first-color);
}

/* .show-menu {
    left: 0;
} */
/* .navbar-toggle {
  display: none !important;
} */

@media screen and (min-width: 769px) {
    .navBar-container {
        all: unset;
    }
    .navBar-toggle {
      display: none !important;
    }
    .navBar {
        position: fixed;
        inset-block: 0;
        right: 30px;
        display: flex;
        align-items: center;
        z-index:10
    }
    .navBar-list-items {
        margin-block: 1rem; 
    }
    .navBar-link {
        padding: 0;
        border-bottom: none;
        background-color: var(--container-color);
        width: 3rem;
        height: 3rem;
        /* width: 60px;  */
        /* height: 60px;  */
        border-radius: 50%;
        position: relative;
    }
    .navBar-name {
        position: absolute;
        top: 0;
        right: 5vw; 
        color: var(--first-color); 
        /* height: 100%; */
        line-height: 3.3.4rem; 
        /* padding-inline: 10px; */
        border-radius: 0 30px 30px 0;
        font-size: var(--large-font-size);
        color: var(--first-color); 
        opacity: 1;
        visibility: visible;
        transition: all 0.3s ease;
        z-index: 10;
    }
    .navBar-icon {
        font-size: 2vw; 
        margin-inline: auto;
        padding-top: 0.1em
    }
    .navBar-link:hover,
    .active-nav {
        background-color: var(--first-color);
    }
    .navBar-link:hover .navBar-icon,
    .active-nav .navBar-icon {
        color: var(--text-white);
    }
    /* .navBar-icon,
    .navBar-name {
    color: var(--first-color);
    font-size: 1.2vw; 
} */
}

@media screen and (min-width: 769px) and (max-width:1023px){
  /* .navBar-toggle {
    display: none !important;
  } */
    .navBar-name {

        line-height: 2rem; 

    }

}



@media screen and (max-width: 768px) {
  .navBar-list {
    display: none;
  }
  .navBar-container {
    all: unset;
  }
  .show-menu .navBar-list {
    display: flex;
    flex-direction:column;
    justify-content: flex-end;
    right: 0px !important;
    width: 100%;
    top: 3.53rem;
    position: fixed;
    z-index: 5;
    background-color: var(--container-color)
  }

  .navBar-name {
    font-size: var(--h3-font-size)
  }
  .navBar-list-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 3rem;
    align-items: flex-start;
    border-bottom: 1px solid var(--title-color);
  }

  .navBar-link {
    display: flex;
    align-items: center;
    /* column-gap: 1rem; */
    border-bottom: none;
    padding-block: 0.8rem; 
    /* position: relative; */
}


  .navBar-toggle {
    display: block;
    position: fixed;
    top: 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    right: 0px; 
    z-index: 10;
    width:100vw;
    height:3.55rem;
    border-bottom: 1px solid var(--title-color);
    background-color: var(--text-white);
  }

  .burger {
    width:2rem;
    height:2rem;
  }
      .navBar-icon,
    .navBar-name {
    color: var(--first-color);
    font-size: var(--h1-font-size);
    /* padding-left: 1rem;
    padding-right: 1rem;  */
}
.navBar-name {
  padding-left: 5rem;
  width: 10rem;
}
.navBar-icon {
  line-height: 10px;
  /* margin-bottom: 5rem; */
  width: 10rem;
  padding-right: 5rem;

}
}


.burger {
    display: block;
    width: 20px;
    height: 3px;
    background-color: var(--title-color);
    margin: 4px auto;
    transition: all 0.3s ease;
}

.navBar-icon {
  color: var(--title-color);
  font-size: 1.3rem;
}

.show-menu .burger:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 5px);
}

.show-menu .burger:nth-child(2) {
    opacity: 0;
}

.show-menu .burger:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -5px);
}