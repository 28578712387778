.research-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column
} 

.research-description {
  font-size: var(--large-font-size);
  width:1100px;
}

.research-publication-container {
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.research-description-separator {
  height: 1.5rem;
}
.research-description {
  width: 80%;
  font-size: var(--large-font-size);
  padding-inline: 7vw
}

.research-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.research-hover {
  background-color: var(--first-color);
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  opacity: 0;
  transition: all .5s ease;
}

.research-item:hover .research-hover {
  opacity: 1;
} 

.research-title {
  color: var(--text-white);
  font-size: var(--large-font-size);
  font-weight: var(--weight-500);
  transform: translateY(-20px);
  padding-top: 2.5rem

} 

.research-img {
  height: 16rem;
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.research-modal {
  position: fixed;
  inset: 0;
  background-color: hsla(0, 0, 0, 0.7);
  z-index: 1000;
  display: grid;
  place-items: center;
  padding: 15px;
}

.research-modal-content {
  background-color: var(--container-color);
  max-width: 650px;
  width: 100%;
  height: 80vh;
  padding: 32px;
  border-radius: 10px;
  position: relative;
  overflow-y: auto;
}

.modal-close {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  filter: invert(0.8);
  z-index: 5;
  margin-right: 5px;
  margin-top: 5px;
}

.modal-title {
  text-align: center;
  color: var(--first-color);
  font-size: var(--h2-font-size);
  letter-spacing: 2px;
  margin-bottom: 24px;
}

.modal-list {
  grid-template-columns: (3, 1fr);
  row-gap: 10px;
  margin-bottom: 24px;
}

.modal-item {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 10px;
}

.item-title {
  font-size: var(--large-font-size);
  font-weight: 700;
  color: var(--title-color);
  font-style: italic;
  padding-bottom: 10px;
}
.item-details {
  font-size: var(--normal-font-size);
  color: var(--title-color);
  font-weight: var(--weight-600);
  padding-bottom: 10px;
}
.download-button {
  position: relative;
  float:right;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .research-publication-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 576px) {
  .research-modal-content {
    max-width: 100%;
  }

  .modal-list {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}



@media screen and (min-width: 481px) and (max-width: 768px) {

  .research-description-separator {
    height: 1.4rem;
  }
  .research-description {
    width : 90vw;
    font-size: var(--h1-font-size);
    padding-inline: 2rem
  }
  .research-publication-container {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .research-img {
      height: 8rem;
      width: 100%;
      object-fit: cover;
      vertical-align: middle;
  }
  .research-title {
    font-size: var(--h3-font-size);
  }
  .modal-close {
    width: 1.5rem;
    height: 1.5rem;
  }
  .modal-title {
    font-size: var(--big-font-size);
    margin-bottom: 1.2rem;
  }
  .modal-item {
    padding: 1.2em;
  }
  .item-title {
    font-size: var(--h2-font-size);
  }
  .item-details {
    font-size: var(--h3-font-size);
  }
  .research-button {
    font-size: 0.56rem;
    border-radius: 2rem;
    padding: 6px 35px 6px 6px;

  }

  .research-button-icon {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 0.8rem;
    line-height: 2rem;
  }

}



@media  screen and (max-width: 480px) {
  .research-description-separator {
    height: 1rem;
    }
  .research-description {
    width : 90vw;
    font-size: var(--h1-font-size);
    padding-inline: 2rem
  }
  .research-publication-container {
    grid-template-columns: repeat(2, 1fr)
  }
  .research-img {
      height: 6.5rem;
      width: 100%;
      object-fit: cover;
      vertical-align: middle;
  }
  .research-title {
    font-size: var(--h3-font-size);
  }
  .modal-close {
    width: 1.2rem;
    height: 1.2rem;
  }
  .modal-title {
    font-size: var(--h1-font-size);
    margin-bottom: 1rem;
  }
  .modal-item {
    padding: 1em;
  }
  .item-title {
    font-size: var(--h3-font-size);
  }
  .item-details {
    font-size: var(--h4-font-size);
  }
  .research-button {
    font-size: 0.4rem;
    border-radius: 2rem;
    padding: 6px 35px 6px 6px;

  }

  .research-button-icon {
    width: 1.6rem;
    height: 1.6rem;
    font-size: 0.75rem;
    line-height: 1.8rem;
  }

}