.section-container {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center
}

.about-container,
.education-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* align-items: flex-start; */
    gap: 20px;
  }

.about-container {
  left: 50%;
}
.about-page-container {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:0; */
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 0rem;
    width:80%
  }

.about-separator {
    display: none;
}
.resume-button {
  display: none;
  width: 13rem;
  font-size:0.9rem;
  /* margin-top: ; */
}
.resume-button-icon {
  font-size: 2rem;
  right: -2px;
  width: 3.6rem;
  height: 3.6rem; 
  font-size:1.8rem; 
  line-height: normal;
  padding-top: 0.75rem;
}

.about-resume-button {
  display: flex;
  position: relative;
  /* left:  29vw; */
  /* top: 8.5vw; */
  bottom: 0;
  float: right;
  /* align-items: flex-end; */
  margin-top:2rem;
  
}

.separator {
  border-top: 1px solid var(--border-color);
  max-width: 40%;
  height:8vh;
  /* margin: 72px auto 56px; */
}
.info-list {
  /* row-gap: 20px; */
  margin-bottom: 32px;
  font-size: var(--normal-font-size);
  font-weight: var(--weight-600);
}

.interest-list-title {
  font-weight: var(--weight-600);
  /* padding-top: 1vh; */
}

.section-subtitle {
  text-align: center;
  font-size: var(--h3-font-size);
  font-weight: var(--weight-600);
  margin-bottom: 2vh;
}
.subtitle-center {
  text-align: center;
  margin-bottom: 2vh;
}

.about-description {
  /* width: 60%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--large-font-size);
  /* width: 60%; */
  /* text-indent: 4vw; */
  /* text-align: center; */
  line-height: 2.5vw;
}

.education-item {
    position: relative;
    padding-left: 4vw;
    margin-bottom: 2vh;
  }

.education-item:not(:last-child) {
    margin-bottom: 1vh !important;
  }

.education-item::before {
    content: '';
    position: absolute;
    inset-block: 0;
    left: 2vw;
    border-left: 1px solid var(--border-color);
    margin-bottom: 0;
  }

.education-icon {
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--first-color);
    color: var(--text-white);
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    /* margin-right: 1vw; */
  }
  
.education-date {
  background-color: var(--container-color);
  padding: 2px 10px;
  border-radius: 20px;
  font-size: var(--tiny-font-size);
  font-weight: var(--weight-600);
}
.education-title {
  font-size: var(--normal-font-size);
  font-weight: var(--weight-600);
  /* margin-block: 20px 10px; */
}

.education-title span {
  color: var(--text-color);
  font-family: var(--body-font);
  font-size: var(--small-font-size);
  font-weight: var(--weight-600);
  position:relative;
  /* padding-left: 26px; */
}



.interest-title {
  padding-right: 5vw;
}


.education-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
}

.education-data {
  grid-column: 1/3;
  margin-right: 4vw;
}

.interest-icons {
  display: flex;
  flex-direction: column;
}

.interest-icon {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--first-color);
  color: var(--text-white);
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  text-align: center;
  line-height: normal !important;
  padding-top:0.6vh;
  font-size: 2vw;
}

.interest-list-container {
  display: flex;
  flex-direction: row;
}

.interest-container {
  grid-column: 3 / span 2;
}

.interest-list-container::before {
  content: '';
  position: absolute;
  inset-block: 0;
  left: 2vw;
  border-left: 1px solid var(--border-color);
  margin-bottom: 0;
  /* z-index: 1; */
}

.interest-list-container {
  position: relative;
  padding-left: 4vw;
  margin-bottom: 1vh;
  /* z-index: 2; */
}


@media screen and (min-width: 769px) and (max-width: 1024px) {

  .resume-button {
    display: none;
    width: 11.5rem;
    /* font-size: var(--h4-font-size); */
  }
  .resume-button-icon{
    font-size: 1.5rem;
    line-height:normal;
    padding-top: 0.75rem;
    width: 3.2rem;
    height: 3.2rem;
  }
  .about-resume-button {
    display:flex;
    /* left: 2vw; */
    font-size:0.7rem;
    top: 11.5vw
  }
}
@media screen and (max-width: 768px) {
  
  .form-container {
    padding: 1rem;
    border-radius: 0.75rem;
    width: 75vw;
  }
  .about-page-container {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:0; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0;
    width: 100%;
    
  }
  .form-field textarea {
    padding: 5px;
    resize: both;
  }

  /* .resume-button {
    font-size: 1rem;
    padding: 8px 35px 8px 0px;
    padding-right: 10px;
    width: 60%;
  } */

  .resume-button {
    display: flex;
    width:10rem;
    padding-left: 3vw;
    font-size: 0.7rem;
  }
  .about-resume-button {
    display: none;
  }
  .resume-button-icon {
    font-size: 2rem;
    line-height: normal !important;
    padding-top: 8px !important;
  }
  .resume-button-icon {
    background-color: var(--first-color);
    position: absolute;
    /* inset-block: -1px; */
    right: 0;
    width: 45px;
    border-radius: 100%;
    color: var(--text-white);
    font-size: 2rem;
    text-align: center;
    line-height: 45px;
  }
  .about-separator {
    display: flex;
  }
  .about-description {
    width: 100%;
    padding-inline: 4rem;
    font-size: var(--h2-font-size);
    line-height:3vw;
  }
  .separator {
    height: 4vh;
  }

}



/* @media and screen (max-width:) */

@media screen and (max-width: 480px) {
  .section-title {
    font-size: var(--xbig-font-size);
    padding-top:9vh;
    /* padding-bottom: 2vh; */
  }
  .separator {
    height:3vh;
  }


  .about-description {
    display: inline-block;
    padding-inline: 4rem;
    justify-content: center;
    align-items: center;
    font-size: var(--h2-font-size); 
    width:100%;
    /* text-indent: 2rem; */
    line-height:3vw;
  }
  .form-container {
    padding: 0.4rem;
    width: 100%;
  }

  .form-field textarea {
    padding: 2px;
    resize: both;
  }

  .resume-button {
    display: flex;
    font-size: 1rem;
    padding: 6px 35px 6px 6px;
    padding-right: 35px;

  }
  .about-resume-button {
    display:none;
    /* left: 2vw; */
    font-size:0.7rem;
    top: 11.5vw
  }

  .resume-button {
    width: 33% !important;
    font-size: var(--h2-font-size);
    padding-left: 3vw !important
  }

  .resume-button-icon {
    font-size: 1rem !important;
    width: 1.9rem !important;
    height: 1.9rem !important;
    font-size: 3.2vw !important;
    line-height: normal !important;
    /* padding-top: 8px !important; */
  }
  /* .resume-button-icon {
    background-color: var(--first-color);
    position: absolute;
    inset-block: -1px;
    right: 0;
    width: 32px;
    border-radius: 100%;
    color: var(--text-white);
    font-size: var(--large-font-size);
    text-align: center;
    line-height: 39px;
  } */
  .education-data {
    grid-column: 1/3;
    padding-left: 5vw;
  }
  .education-item {
    position: relative;
    padding-left: 5vw;
    margin-bottom: 3vh;
  }



.education-icon {
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--first-color);
    color: var(--text-white);
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    /* padding-right: */
    /* margin-right: 1vw; */
  }
  
.education-date {
  background-color: var(--container-color);
  padding: 1px 3px;
  border-radius: 20px;
  font-size: var(--normal-font-size);
  font-weight: var(--weight-600);
}
.education-title {
  font-size: var(--h4-font-size);
  font-weight: var(--weight-600);
  /* margin-block: 20px 10px; */
}

.education-title span {
  color: var(--text-color);
  font-family: var(--body-font);
  font-size: var(--h5-font-size);
  font-weight: var(--weight-600);
  position:relative;
  /* padding-left: 26px; */
}
.interest-list-container {
  display: flex;
  flex-direction: row;
}

.interest-container {
  grid-column: 3 / span 2;
}

.interest-list-container::before {
  content: '';
  position: absolute;
  inset-block: 0;
  left: 2vw;
  border-left: 1px solid var(--border-color);
  margin-bottom: 0;
  /* z-index: 1; */
}

.interest-list-container {
  position: relative;
  padding-left: 5vw;
  margin-bottom: 0;
  /* z-index: 2; */
}

.interest-icon {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--first-color);
  color: var(--text-white);
  width: 4.1vw;
  height: 4.1vw;
  border-radius: 50%;
  text-align: center;
  line-height: 2vh;
  padding-top:0.25vh;
  font-size: 3vw;
}
.info-list {
  row-gap: 20px;
  margin-bottom: 10vh;
  font-size: var(--h4-font-size);
  font-weight: var(--weight-600);
}

.interest-list-title {
  font-weight: var(--weight-600);
  padding: 0.2rem;
}
.section-subtitle {
  font-size: var(--h1-font-size);
}

}


@media screen and (max-width: 380px) and (max-height: 668px) {
  .resume-button-icon {
    height:4vh;
  }
}





