.resources-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column
  } 

.resources-description {
  font-size: var(--large-font-size);
  margin-bottom: 40px;
}

.accordion-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    width: 600px;
    font-size: 20px;
    margin-top: 10px;
  }
  
  .underline {
    width: 100%;
    height: 0.15rem;
    background: var(--first-color);
    margin: 20px auto;
  }

  .column-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }
  
  .column-container:hover {
    background-color: var(--gh-blue-1);
    width: 100%;
  }
  
  .text-container {
    text-align: left;
    max-height: 350px;
    opacity: 1;
    animation-name: textanimate;
    animation-duration: 1s;
  }

  .expendable-button {
    color: var(--first-color);
    cursor: pointer;
    width: 2px;
    margin-right: 10px;
    size: lg;
  }
  
  @keyframes textanimate {
    0% {
      opacity: 0;
      max-height: 15px;
    }
    100% {
      opacity: 1;
      max-height: 350px;
    }
  }
  