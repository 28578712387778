@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Poppins:wght@500;600;700;800&display7=swap');

:root {
  --first-color: hsl(2, 76%, 53%);
  --title-color: hsl(0, 0%, 40%);
  --text-color: hsl(0, 0%, 50%);
  --body-color: hsl(0, 0%, 100%);
  --container-color: hsl(0, 0%, 89%);
  --footer-color: hsl(0, 0%, 71%);
  --border-color: hsl(0, 0%, 87%);
  --text-white: #fff;
  --hamburger-color: hsl(0, 0%, 85%);


--body-font: 'Open Sans', sans-serif;
--second-font: 'Poppins', sans-serif;

--xbig-font-size: 5vw;
--big-font-size: 3.9vw;
--h1-font-size: 3vw;
--h2-font-size: 2.5vw;
--h3-font-size: 2vw;
--h4-font-size: 1.8vw;
--h5-font-size: 1.5vw;
--large-font-size: 1.1vw;
--normal-font-size: 1.0vw;
--small-font-size: 0.9vw;
--smaller-font-size: 0.75vw;
--tiny-font-size: 0.5vw;

--weight_400: 400;
--weight_500: 500;
--weight_600: 600;
--weight_700: 700;
--weight_800: 800;

--textarea-rows: 1;
--textarea-cols: 50;
}

* {
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

body,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--weight-500);
  resize: none;
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
p {
  color: var(--title-color);
  font-family: var(--second-font);
  font-weight: var(--weight-700);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button,
input,
textarea {
  background-color: transparent;
  border: none;
  outline: none; 
}

img {
  max-width: 100%;
}


.container {
  max-width: 65vw;
  margin-inline: auto;
}

.grid {
  display: grid;
  gap: 30px;
}

.section {
  padding-block: 70px;
}

.section-title {
  font-size: var(--big-font-size);
  font-weight: var(--weight_800);
  text-align: center;
  margin-bottom: 2vh;
  padding-top: 8vh
}

.section-title span {
  color:var(--first-color)
}

.button {
  display: inline-block;
  color: var(--title-color);
  border: 1px solid var(--first-color);
  padding: 15px 70px 15px 35px;
  border-radius: 35px;
  font-family: var(--second-font);
  font-size: var(--small-font-size);
  font-weight: var(--weight-500);
  position: relative;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  z-index: 1;
}

.home-button:hover {
  color: var(--text-white) !important;
}
.button::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--first-color);
  transform: translateX(100%);
  z-index: -1;
  transition: all .3s ease-out;
}

.button:hover::before {
  transform: translateX(0);
}
.button-icon {
  background-color: var(--first-color);
  position: absolute;
  inset-block: -2px;
  right: 0;
  width: 55px;
  border-radius: 50%;
  color: var(--text-white);
  font-size: var(--large-font-size);
  text-align: center;
  line-height: 60px;
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .container {
    max-width: 80vw;
  }
}
@media screen and (max-width: 480px) {
  .grid {
    gap: 1rem;
  }
  .container {
    max-width:90vw
  }
}