.home {
    grid-template-columns: 4.32fr 7.68fr;
    align-items: center;
    padding: 40px;
    height: 100vh;
}

.home__img {
    /* height: 100%; */
    object-fit: contain;
    /* border-radius: 30px; */
    /* box-shadow: 0 0 8px hsla(0, 0%, 0%, .3); */
    z-index: 3;
    width: 100%;
    align-self: self-end;
    margin-bottom: 0;
    padding-bottom:0;
}

.home__data {
    width: 38vw;
    /* margin-inline: auto; */
    padding-top: 20px;
}

.home__title {
    position: relative;
    font-size: var(--h1-font-size);
    line-height: 1.24;
    padding-left: 7vw;
    width: 120%;
}

.home__title span {
    color: var(--first-color);
}
.home__title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 25%;
    width: 5vw;
    height: 5px;
    border-radius: 10px;
    background-color: var(--first-color);
}

.home__description {
    line-height: 2.125;
    margin-block: 16px 32px;
    font-size: 1.5vw;
}

.button:hover p {
    color: var(--text-white)
}

.color__block {
    position: fixed;
    height: 200%;
    width: 100%;
    background-color: var(--first-color);
    top: -50%;
    left: -83%;
    transform: rotate(-15deg);
}

.home-button-icon {
    right: -2px;
}


@media screen and (min-width: 1150px){
.home__data {
    margin-inline: 2vw;
    }
.home__title::before {
    width: 3.8vw;
    }
.home__title {
    padding-left: 6vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 1149px) {
    .home-button-text {
        font-size: 1.5vw;
    }
    .home-button-icon {

    }
}

@media screen and (min-width: 769px) and (max-width:3600px) {
.color__block {
    position: fixed;
    height: 200%;
    width: 100%;
    background-color: var(--first-color);
    top: -50%;
    left: -88%;
    transform: rotate(-10deg);
    } 
}



@media screen and (max-width: 768px) {
    .home-button-text {
        padding-left: 3vw;
        width: 100%;
        margin-right: 0;
        font-size:1.8vw;
        height: 75%;
        /* padding-right: 5px; */
    }
    .home-button {
        height: 4.3vh;
        width: 25vw;
    }

    .home-button-icon {
        width: 2.5rem !important;
        height: 2.5rem !important;
        /* inset-block: -2px !important; */
        /* text-align: center !important; */
        font-size:1rem !important;
        line-height: normal !important;
        padding-top: 0.75rem !important;
        /* inset-block: -2px!important; */
      }
/* 
    .home-button-icon {

        border-radius:75%;
    } */
}


@media screen and (max-width: 480px) {
    .home__img {
        display: none;
    }
    .home {
        padding: 20px
    }
    .home__content {
        display: flex;
        justify-content: center;
        width: 90vw;
        /* padding-bottom: 5rem; */
    }

    .home__data {
        width: 80%;
    }
    .home__title::before {
        display: none;
        height: 3px;
    }
    .home-button-text {
        font-size:0.5rem;
    }
    .home-button {
        height: 1.5rem;
        width: 7.5rem;
        position: relative;
        float: right;
    }
    /* .home-button-icon {
        background-color: var(--first-color) !important;
        position: absolute!important;
        inset-block: -5px!important;
        right: 0!important;
        width: 24px!important;
        border-radius: 100%!important;
        color: var(--text-white)!important;
        font-size: var(--large-font-size)!important;
        text-align: center!important;
        line-height: 20px!important;
      } */

      .home-button-icon {
        width: 1.7rem !important;
        height: 1.7rem !important;
        /* inset-block: -2px !important; */
        /* text-align: center !important; */
        font-size:0.9rem !important;
        line-height: normal !important;
        padding-top: 5px !important;
        /* inset-block: -2px!important; */
      }
      .home__title {
        font-size:7vw;
        padding-left:0;
      }
      .home__description {
        font-size:4vw;
        width:70vw;
      }
      .color__block {
        display:none !important;
      }
}

