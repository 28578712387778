.teaching-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin-bottom: 5vh; */
    
}

.teaching-title {
    font-size: 2vw;
}

.teaching-description {
    padding-top: 5vh;
    /* margin-bottom: 4vh; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    font-size: var(--large-font-size);
    /* text-align: center; */
    /* font-size: var(--h1-font-size); 
    width:78%; */
    text-indent: 3rem;
    
}

.teaching-description-container {
    width: 65%;
    padding-bottom: 3vh;

}

.course-header {
    font-size: 3vw;
    text-decoration: underline;
    /* margin-bottom: 4vh; */
}

.class-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 65%;
}

.column-4 {
    grid-column: 2;
    grid-row: 2;
}

.column-8 {
    grid-column: 2;
    grid-row: 4;
}

.class-image-container {
    object-fit: contain;

}

.class-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* padding-top: 9vh; */
}
.class-details-container, 
.class-image-container {
    margin-left: 1.5em; 
    margin-right: 1.5em;
    margin-bottom: 1.5em
}

.course-description {
    font-size: 1.1vw;
}
.class-title {
    font-size: 1.8vw;
    padding-bottom: 1vh;
}

@media screen and (max-width: 480px) {

    .class-container {
        width: 95%
    }
    .class-title {
        font-size: 2.6vw;
        padding-bottom: 1vh;
    }

    .course-description {
        font-size: 1.7vw;
    }
    .class-details-container {
        /* padding-top: 3vh; */
    }
    .class-details-container, 
    .class-image-container {
        margin-left: 0.5em; 
        margin-right: 0.5em;
        margin-bottom: 0.5em
    }
    .teaching-description-container {
        /* font-size: 2vw; */
        width: 78%;
        padding-bottom: 0;
    }
    .course-header {
        font-size: 5vw;
        padding-top: 0;
        font-weight: var(--weight_700)
    }
    .teaching-description {
        font-size: 3vw;
        padding-bottom: 0;
        padding-top: 0;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .class-title {
        font-size: 3.0vw !important;
        padding-bottom: 1.8vh;
    }
    .class-container {
        width: 90%
    }

    .course-description {  
        font-size: 2vw

    }
    .class-details-container {
        /* padding-top: 5vh; */
    }
    .class-details-container, 
    .class-image-container {
        margin-left: 0.75em; 
        margin-right: 0.75em;
        margin-bottom: 0.75em
    }
    .teaching-description-container {
        font-size: 2.5vw;
        width: 85%;
        padding-bottom: 4vh
        
    }
    .course-header {
        font-size: 6vw;
    }
    .teaching-description {
        font-size: 10vw;
    }
}