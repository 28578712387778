.contact-form-wrapper{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    background-color: var(--footer-color);
    /* width: 35vw; */
    height: 100%;

}
.contact-form-container {
    background-color: var(--footer-color);
    padding: 2rem;
    border-radius: 1rem; 
    z-index: 1;
    width: 35vw;  

}

.form-field textarea {
    border-radius:0.75rem;
    background-color:rgb(216, 216, 216);
    padding-left: 10px;
    width: 100%;
    overflow: hidden;
    /* padding-top: 0.8vh; */
    font-size: var(--large-font-size);
}

.form-field {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start ;
    margin-bottom: 1rem;
    height: 3vh;
}

.form-field-large {
  height: 22vh;
}

.textarea-large {
  height: 100%;
}


.form-field label {
    margin-bottom: 0.25rem;
    font-weight: bold;
}

.form-title {
    color: var(--text-white);
    padding-bottom: 1rem;
    text-shadow: 3px 3px 2px rgba(76, 76, 76, 1);
    width: 100%;
}



.contact-button-icon {
    font-size: var(--h3-font-size);
    line-height: 60px;
} 
.contact-button {
  width: 15rem;
}
.button {
    display: inline-block;
    color: var(--title-color);
    border: 1px solid var(--first-color);
    padding: 14px 70px 14px 35px;
    border-radius: 35px;
    font-family: var(--second-font);
    font-size: 1rem;
    font-weight: var(--weight-500);
    position: relative;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    /* z-index: 1; */
    /* bottom: 0;
    right: 0; */
  }

  
  .button:hover {
    color: var(--text-white);
    /* background-color: var(--first-color) !important; */
   
    
  }
  .button::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--first-color) !important;
    transform: translateX(100%);
    z-index: -1;
    transition: all .3s ease-out;
  }
  
  .button:hover::before {
    transform: translateX(0) !important;
    /* z-index: -1; */
    /* background-color: var(--first-color) !important; */
 
  }
  .button-icon {
    background-color: var(--first-color);
    position: absolute;
    /* inset-block: -2px; */
    right: -2px;
    width: 3.5rem;
    border-radius: 50%;
    color: var(--text-white);
    font-size: 1.6rem;
    /* text-align: center; */
    /* line-height: 6px; */
    /* padding-top: 0.1rem; */
  }

  @media screen and (max-width: 768px) {
  .contact-form-container {
    padding: 1rem;
    border-radius: 0.75rem;
    width: 75vw;
  }

  .form-field textarea {
    padding: 5px;
    resize: both;
    font-size: var(--h3-font-size) !important;
    /* padding-bottom: 2.5vh !important; */
  }

  .form-title {
    font-size: var(--h1-font-size) !important;
  }
  .button {
    font-size: 0.8rem;
    padding: 7px 38px 7px 0px;
    padding-right: 17px;
    /* width: 60%; */
  }
  .contact-button {
    width:45%
  }
  .button-icon {
    font-size: 1rem;
  }
  .contact-button-icon {
    background-color: var(--first-color);
    position: absolute;
    inset-block: -1px;
    /* right: 0; */
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 100%;
    color: var(--text-white);
    font-size: 1.2rem;
    text-align: center;
    line-height: 42.5px;
  }
}

@media screen and (max-width: 480px) {
  .contact-form-container {
    padding: 0.4rem;
    width: 100%;
  }

  .form-field textarea {
    /* padding: 3px; */
    resize: both;
  }
  .form-field textarea {
    padding: 1rem;
    resize: both;
    font-size: var(--h1-font-size) !important;
    /* padding-bottom: 1vh !important;
    padding-top:0.5vh !important; */
  }

  .form-title {
    font-size: var(--xbig-font-size) !important;
  }

  .button {
    font-size: 12px;
    padding: 6px 35px 6px 6px;
    padding-right: 35px;

  }

  .contact-button {
    width: 45%
  }

  .contact-button-icon {
    font-size: 1rem !important;
  }
  .contact-button-icon {
    background-color: var(--first-color);
    position: absolute;
    inset-block: -1px;
    /* right: 0; */
    width: 2.05rem;
    height: 2.05rem;
    border-radius: 100%;
    color: var(--text-white);
    font-size: var(--large-font-size);
    text-align: center;
    line-height: 39px;
  }
}

